import React from "react";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import TitleImageComponent from "../components/image/TitleImageComponent";

const SuccessNewsletterPage = () => {

    return (
        <Grid>
            <TitleImageComponent
                textStore={"newsletter"}
                namespace={'success-newsletter-page'}
                imageLinkId={"success-newsletter-page-title-image-id"}
                headerId={"success-newsletter-page-title-header-id"}
                subheaderId={"success-newsletter-page-subtitle-header-id"}
                buttonTextId={"success-newsletter-page-button-text-id"}
                buttonLinkId={"success-newsletter-page-button-link-id"}
                small
            />
            <Grid.Row centered style={{height: "100%"}}>
                <Grid.Column style={{height: "100%"}}>
                    <h2 className={"text-center"}>Vielen Dank für Ihre Anmeldung zu unserem Newsletter.</h2>
                    <h3 className={"text-center"}>Sie erhalten in Kürze eine automatisierte
                        E-Mail. Bitte bestätigen Sie Ihre Anmeldung. </h3>
                    <h4  className={"text-center"}><em>Hinweis: Sie können der Nutzung Ihrer E-Mail-Adresse jederzeit mit Wirkung für die Zukunft
                        widersprechen. Nutzen Sie hierfür den Abmelde-Button im Newsletter oder senden Sie einen formlosen
                        Widerruf per E-Mail an <a href="mailto:info@buergerstiftung-kreis-rv.de">info@buergerstiftung-kreis-rv.de</a></em></h4>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default SuccessNewsletterPage;